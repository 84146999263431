export default [
    {
        path: '/travelers/aesthetic-profile',
        components: {
            default: () => import(/* webpackChunkName: "public.external.properties" */ '@/views/Travelers/AI/ProfilesList.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "travelers.footer" */ '@/components/Layout/TravelersFooter.vue'),
        },
        meta: {
            group: '/ai',
            multiRoute: 'public.ai.profile',
            label: 'Aesthetic Profiles',
        }
    },
  ];