<template>
    
    <prompt :showButtons="false" v-if="!inline" :title="title" :peekSize="6" :peekAutoSize="true" :open="showPopover" @hide="hidePopover" placement="bottom">
        <template #default>
            <input-group ref="inputGroupEl">

                <form-input name="start-date" :placeholder="this.startLabel || 'From'" v-model="typedStartDate" :prefixedEvents="true" @v:change="selectTypedStartDate" @focus="focusStartTypeDate" @click.prevent.stop />

                <form-input name="end-date" :placeholder="this.endLabel || 'To'" v-model="typedEndDate" :prefixedEvents="true" @v:change="selectTypedEndDate" @focus="focusEndTypeDate" @click.prevent.stop />

                <input-group-text class="clickable">
                    <i class="fas fa-calendar-week"></i>
                </input-group-text>
            </input-group>
            <small class="text-danger" v-if="invalidTypedDate">{{ invalidTypedDateError }}</small>
        </template>
        <template #field>
            <!-- the same chunk of code is used in the v-else for inline placement -->
            <!-- when updating this chunk, make sure to update the second chunk -->
            <div>
                <div class="multi-calendar-container" :class="calendarContainerCssClass">
                    <div v-for="(month, index) in months" :key="`single-range-calendar-month-${index}`" :class="(index > 0) ? 'ms-4' : null" style="min-width:260px;">
                        <calendar-month v-bind="monthProps(month, index)" @monthChange="(newMonth) => monthChange(newMonth, index)" @dateClick="dateClick" @dateEnter="dateEnter" @calendarLeave="calendarLeave" :monthButtonSize="monthButtonSize" :monthButtonCssClass="monthButtonCssClass">

                            <template v-for="(wedgeTypes, slotName) in wedges" :key="`single-range-calendar-hover-wedge-${slotName}`" #[slotName]>
                                <template v-for="wedgeType in wedgeTypes" :key="`single-range-calendar-hover-wedge-${slotName}-${wedgeType}`">
                                    <slot v-if="wedgeType == 'slot'" :name="slotName"/>
                                    <calendar-start-wedge v-else-if="(wedgeType.indexOf('start') != -1)" :color="(wedgeType.indexOf('hover') != -1) ? computedHoverColor : computedRangeColor" :circle="circleWedges" />
                                    <calendar-end-wedge v-else-if="(wedgeType.indexOf('end') != -1)" :color="(wedgeType.indexOf('hover') != -1) ? computedHoverColor : computedRangeColor" :circle="circleWedges"/>
                                    <calendar-full-wedge v-else-if="(wedgeType.indexOf('full') != -1)" :color="(wedgeType.indexOf('hover') != -1) ? computedHoverColor : computedRangeColor" :circle="circleWedges" :singleDate="(wedgeType.indexOf('single') != -1)"/>
                                    
                                </template>
                            </template>

                        </calendar-month>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-baseline" v-if="showClearButton">
                    <div :class="instructionsCssClass">
                        {{ instructions }}
                    </div>
                    <div>
                        <ui-button color="light" size="sm" @click="clear" :disabled="!this.startDate && !this.endDate">Clear</ui-button>
                        <slot name="buttons"/>
                    </div>
                </div>

                <slot name="footer"/>
            </div>
        </template>
    </prompt>
    <!-- second chunk for inline placement -->
    <div v-else>
        <div class="multi-calendar-container" :class="calendarContainerCssClass">
            <div v-for="(month, index) in months" :key="`single-range-calendar-month-${index}`" :class="(index > 0) ? 'ms-4' : null">
                <calendar-month v-bind="monthProps(month, index)" @monthChange="(newMonth) => monthChange(newMonth, index)" @dateClick="dateClick" @dateEnter="dateEnter" @calendarLeave="calendarLeave" :monthButtonSize="monthButtonSize" :monthButtonCssClass="monthButtonCssClass">

                    <template v-for="(wedgeTypes, slotName) in wedges" :key="`single-range-calendar-hover-wedge-${slotName}`" #[slotName]>
                        <template v-for="wedgeType in wedgeTypes" :key="`single-range-calendar-hover-wedge-${slotName}-${wedgeType}`">
                            <slot v-if="wedgeType == 'slot'" :name="slotName"/>
                            <calendar-start-wedge v-else-if="(wedgeType.indexOf('start') != -1)" :color="(wedgeType.indexOf('hover') != -1) ? computedHoverColor : computedRangeColor" :circle="circleWedges"/>
                            <calendar-end-wedge v-else-if="(wedgeType.indexOf('end') != -1)" :color="(wedgeType.indexOf('hover') != -1) ? computedHoverColor : computedRangeColor" :circle="circleWedges"/>
                            <calendar-full-wedge v-else-if="(wedgeType.indexOf('full') != -1)" :color="(wedgeType.indexOf('hover') != -1) ? computedHoverColor : computedRangeColor" :circle="circleWedges" :singleDate="(wedgeType.indexOf('single') != -1)"/>
                        </template>
                    </template>

                </calendar-month>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-baseline" v-if="showClearButton">
            <div :class="instructionsCssClass">
                {{ instructions }}
            </div>
            <div>
                <ui-button color="light" size="sm" @click="clear" :disabled="!this.startDate && !this.endDate">Clear</ui-button>
                <slot name="buttons"/>
            </div>
        </div>

        <slot name="footer"/>
    </div>
</template>

<script type="text/javascript">
    import DateRange from '@/classes/Ranges/Date.js';
    import UI from '@/classes/UI.js';
    import Utilities from '@/classes/Utilities.js';
    import DateObject from '@/classes/DateObject.js';
    import Prompt from '@/components/Action/Prompt.vue';

    import CalendarComponents from '@/components/UI/Calendar/index.vue';
    import UiButton from '@/components/UI/Button/Button.vue';
    import InputGroup from '@/components/Form/InputGroup.vue';
    import InputGroupText from '@/components/Form/InputGroupText.vue';
    import FormInput from '@/components/Form/Input.vue';

    import { UseUIStore } from '@/store/UI.js';

    export default {

        components: {
            Prompt,
            UiButton,
            InputGroup,
            InputGroupText,
            FormInput,
            ...CalendarComponents,
        },

        setup() {
            return {
                uiStore: UseUIStore(),
            };
        },

        data() {
            return {
                containerId: Utilities.uniqueId('single-range-calendar'),
                mounted: false,
                localValue: null,

                localDate: null,
                localMin: null,
                localMax: null,

                startDate: null,
                endDate: null,
                hoverDate: null,
                pauseHover: false,

                typedStartDate: null,
                typedEndDate: null,

                showPopover: null,

                invalidTypedDate: false,
            };
        },

        props: {
            date: {
                type: [DateObject, Date, String],
                default: null,
            },

            min: {
                type: [DateObject, Date, String],
                default: null,
            },

            max: {
                type: [DateObject, Date, String],
                default: null,
            },

            modelValue: {
                type: DateRange,
            },

            value: {
                type: DateRange,
            },

            calendarProps: {
                type: Object,
            },
            
            numberOfMonths: {
                type: Number,
                default: 1,
            },

            step: {
                type: Number,
                default: null,
            },

            inline: {
                type: Boolean,
                default: false,
            },

            rangeColor: {
                type: String,
            },

            hoverColor: {
                type: String,
            },

            title: {
                type: String,
            },

            startLabel: {
                type: String,
                default: 'From',
            },

            endLabel: {
                type: String,
                default: 'To',
            },

            extendEndWedge: {
                type: Number,
            },

            format: {
                type: String,
                default: 'M jS Y',
            },

            showClearButton: {
                type: Boolean,
                default: true,
            },

            startDateInstructions: {
                type: String,
                default: 'Select the start date',
            },

            endDateInstructions: {
                type: String,
                default: 'Select the end date',
            },

            closeOnClear: {
                type: Boolean,
                default: true,
            },

            forwardOnly: {
                type: Boolean,
                default: true,
            },

            minDates: {
                type: Number,
            },

            allowCrossBlocked: {
                type: Boolean,
                default: false,
            },

            calendarYearsRange: {
                type: Number,
                default: 3,
            },

            calendarContainerCssClass: {
                type: String,
            },

            invalidTypedDateError: {
                type: String,
                default: 'This date cannot be selected',
            },

            // to force wedges change after the calendar is rendered
            // we'll add a slots key in the wedges
            slotsKey: {
                type: String,
                default: null,
            },

            // selects the start date watcher, this is used
            // to select the start date when we have two
            // calendars that must be matched
            selectedStartDate: {
                type: DateObject,
            },

            circleWedges: {
                type: Boolean,
                default: false,
            },

            instructionsCssClass: {
                type: String,
                default: 'text-muted small'
            },

            monthButtonSize: {
                type: String,
                default: 'lg',
            },

            monthButtonCssClass: {
                type: String,
            },
        },

        emits: ['update:modelValue', 'change', 'input', 'monthChange', 'clear', 'selectStart', 'selectEnd'],

        created() {
            if (this.modelValue) {
                this.setLocalValue(this.modelValue);
            }
            else {
                this.setLocalValue(this.value);
            }

            this.setMin();
            this.setMax();
            this.setLocalDate();
        },

        mounted() {
            requestAnimationFrame(function() {
                this.mounted = true;
            }.bind(this));
        },

        watch: {
            modelValue(newVal) {
                this.setLocalValue(newVal);
            },

            value(newVal) {
                this.setLocalValue(newVal);
            },

            date() {
                this.setLocalDate();
            },

            min() {
                this.setMin();
            },

            max() {
                this.setMax();
            },
        },

        computed: {

            computedHoverColor() {
                if (this.hoverColor) {
                    return this.hoverColor;
                }

                return UI.rangeCalendarHoverColor(this.circleWedges);
            },

            computedRangeColor() {
                if (this.rangeColor) {
                    return this.rangeColor;
                }

                return UI.rangeCalendarSelectedColor(this.circleWedges);
            },
            
            calendarBindProps() {
                let props = {};
                if (this.calendarProps) {
                    props = {...this.calendarProps};
                }
                
                delete props.showPreviousButton,
                    props.showControllers,
                    props.showNextButton,
                    props.date,
                    props.step;

                props.min = this.computedMin;
                props.max = this.computedMax;
                props.date = this.localDate.copy();

                // if we are moving back and forth and have a min
                // number of dates, we'll add a block range
                if ((this.startDate) && (!this.endDate) && (!this.forwardOnly) && (this.minDates) && (this.minDates > 0)) {
                    let blockStart = this.startDate.copy();
                    blockStart.date -= this.minDates - 1;
                    let blockEnd = this.startDate.copy();
                    blockEnd.date += this.minDates - 1;

                    if (!props.blockDates) props.blockDates = [];
                    props.blockDates.push(new DateRange(blockStart, blockEnd));
                }

                if ((!props.selectableDates) && (!props.selectable)) {
                    props.selectable = true;
                }

                if (this.step) {
                    props.step = this.step;   
                }
                else {
                    props.step = this.monthsCount;
                }

                return props;
            },

            computedMin() {
                let min;
                if (this.localMin) min = this.localMin.copy();

                if ((this.startDate) && (!this.endDate)) {
                    if (this.forwardOnly) {
                        min = this.startDate.copy();
                        if (this.minDates)  {
                            min.date += this.minDates;
                        }
                    }
                    // if not, we'll check if we need to block the start
                    // of the ranges
                    else if (!this.allowCrossBlocked) {
                        if (
                            (this.calendarProps) &&
                            (this.calendarProps.blockDates) &&
                            (this.calendarProps.blockDates.length)
                        ) {
                            let start = this.startDate.copy();
                            if (this.minDates)  {
                                start.date += this.minDates;
                            }
                            let end = this.computedMin;
                            if (!end) {
                                end = start.copy();
                                end.year -= this.calendarYearsRange;
                            }
                            
                            while (start.time >= end.time) {
                                if (this.isBlocked(start, true)) {
                                    min = start;
                                    min.date += 1; // we don't need the blocked date
                                    break;
                                }
                                start.date -= 1;
                            }
                        }
                    }
                }

                return min;
            },

            computedMax() {
                let max;
                if (this.localMax) max = this.localMax.copy();

                if ((this.startDate) && (!this.endDate)) {
                    

                    if (!this.allowCrossBlocked) {
                        if (
                            (this.calendarProps) &&
                            (this.calendarProps.blockDates) &&
                            (this.calendarProps.blockDates.length)
                        ) {

                            let start = this.startDate.copy();
                            if (this.minDates)  {
                                start.date += this.minDates;
                            }
                            let end = max;
                            if (!end) {
                                end = start.copy();
                                end.year += this.calendarYearsRange;
                            }

                            while (start.time <= end.time) {
                                if (this.isBlocked(start, false, true)) {
                                    max = start;
                                    max.date -= 1; // we don't need the blocked date
                                    break;
                                }
                                start.date += 1;
                            }
                        }
                    }
                }

                return max;
            },

            months() {
                let displayMonth = this.localDate.copy();
                displayMonth.date = 1;

                // move back to first possible month if we have a local max
                if ((this.localMax) && (this.monthsCount > 1)) {
                    let moveBackBy = 0;
                    let testDisplayMonth = displayMonth.copy();

                    for (let i=0; i<this.monthsCount; i++) {
                        if (testDisplayMonth.time > this.localMax.time) {
                            moveBackBy++;
                        }
                        testDisplayMonth.month += 1;
                    }

                    if (moveBackBy > 0) {
                        displayMonth.month -= moveBackBy;
                    }
                }
                let months = [];
                for (let i=0; i<this.monthsCount; i++) {
                    if ((this.localMax) && (displayMonth.time > this.localMax.time)) {
                        break;
                    }

                    months.push(displayMonth.copy());
                    displayMonth.month += 1;   
                }

                return months;
            },

            monthsCount() {
                if (this.uiStore.isMobile) return 1;
                else if ((this.uiStore.isTablet) && (this.numberOfMonths > 2)) return 2;
                return this.numberOfMonths || 1;
            },

            wedges() {
                let wedges = {};

                // force wedges change to recompute the wedges
                if (this.slotsKey) {
                    wedges[this.slotsKey] = true;
                    delete wedges[this.slotsKey];
                }

                // add the passed slots into the months slots
                if (this.$slots) {
                    let keys = Object.keys(this.$slots);
                    for (let i=0; i<keys.length; i++) {
                        if (!wedges[keys[i]]) wedges[keys[i]] = [];
                        wedges[keys[i]].push('slot');
                    }
                }

                if ((this.startDate) && (this.endDate) && (this.startDate.time != this.endDate.time)) {

                    let start = this.startDate;
                    let end = this.endDate;

                    if (this.extendEndWedge) {
                        end = end.copy();
                        end.date += 1;
                    }
                    let startSlotName = start.format()+'-wedge';
                    let endSlotName = end.format()+'-wedge';

                    if (!wedges[startSlotName]) wedges[startSlotName] = [];
                    if (!wedges[endSlotName]) wedges[endSlotName] = [];
                    wedges[startSlotName].push('start-select');
                    wedges[endSlotName].push('end-select');

                    let range = new DateRange(this.bindToDisplayMonths(start), this.bindToDisplayMonths(end));
                    let arr = range.exclusiveArray;

                    for (let i=0; i<arr.length; i++) {
                        let slotName = arr[i].format()+'-wedge';
                        if (!wedges[slotName]) wedges[slotName] = [];
                        wedges[slotName].push('full-select');
                    }
                    
                }
                else if ((this.startDate) && (!this.hoverDate)) {
                    let startSlotName = this.startDate.format()+'-wedge';

                    if (!wedges[startSlotName]) wedges[startSlotName] = [];
                    if (this.circleWedges) {
                        wedges[startSlotName].push('full-select-single');
                    }
                    else {
                        wedges[startSlotName].push('full-select');
                    }
                }

                if (this.hoverDate) {
                    
                    if ((this.startDate) && (!this.endDate)) {
                        let start = this.startDate;
                        let end = this.hoverDate;

                        if (this.extendEndWedge) {
                            end = end.copy();
                            end.date += 1;
                        }

                        if (start.time == end.time) {
                            let slotName = this.hoverDate.format()+'-wedge';
                            if (!wedges[slotName]) wedges[slotName] = [];

                            if (this.circleWedges) {
                                wedges[slotName].push('full-select-single');
                            }
                            else {
                                wedges[slotName].push('full-hover');
                            }
                        }
                        else {
                            if (start.time > end.time) {
                                let hold = start;
                                start = end;
                                end = hold;
                            }
                            let startSlotName = start.format()+'-wedge';
                            let endSlotName = end.format()+'-wedge';

                            if (!wedges[startSlotName]) wedges[startSlotName] = [];
                            if (!wedges[endSlotName]) wedges[endSlotName] = [];
                            wedges[startSlotName].push('start-hover');
                            wedges[endSlotName].push('end-hover');

                            let range = new DateRange(this.bindToDisplayMonths(start), this.bindToDisplayMonths(end));
                            let arr = range.exclusiveArray;

                            for (let i=0; i<arr.length; i++) {
                                let slotName = arr[i].format()+'-wedge';
                                if (!wedges[slotName]) wedges[slotName] = [];

                                wedges[slotName].push('full-hover');
                            }
                        }

                    }
                    else {
                        let hoverName = 'full-hover';
                        
                        if (this.circleWedges) {
                            if ((this.startDate) && (this.startDate.equalDate(this.hoverDate))) {
                                hoverName = 'start-hover';
                            }
                            else if ((this.endDate) && (this.endDate.equalDate(this.hoverDate))) {
                                hoverName = 'end-hover';
                            }
                        }
                        
                        let slotName = this.hoverDate.format()+'-wedge';
                        wedges[slotName] = [hoverName];
                    }
                }

                return wedges;
            },

            instructions() {
                if ((this.startDate) && (!this.endDate)) {
                    return this.endDateInstructions;
                }
                else if (!this.startDate) {
                    return this.startDateInstructions;
                }
                return null;
            },
        },

        methods: {

            setLocalValue(val) {
                if (val) {
                    this.localValue = val.copy();
                    this.startDate = this.localValue.start;
                    this.endDate = this.localValue.end;
                }
                else {
                    this.localValue = null;
                    this.typedStartDate = null;
                    this.typedEndDate = null;
                    if ((this.startDate) && (this.endDate)) {
                        this.startDate = null
                        this.endDate = null;
                    }
                }

                if (this.startDate) {
                    this.typedStartDate = this.startDate.format(this.format);
                }
                if (this.endDate) {
                    this.typedEndDate = this.endDate.format(this.format);
                }

                this.invalidTypedDate = false;
            },

            setLocalDate(dt) {
                if (!dt) {
                    if (this.date) {
                        if (!(this.date instanceof DateObject)) {
                            dt = new DateObject(this.date);
                        }
                        else {
                            dt = this.date.copy();
                        }
                    }
                    else if (this.startDate) {
                        dt = this.startDate.copy();
                    }
                    else {
                        dt = new DateObject();
                    }
                }
                else {
                    dt = dt.copy();
                }

                dt.date = 1;
                dt.midDay();

                this.localDate = this.bindToMinMax(dt);
            },

            setMin() {
                if (this.min) {
                    if (!(this.min instanceof DateObject)) {
                        this.localMin = new DateObject(this.min);
                    }
                    else {
                        this.localMin = this.min.copy();
                    }

                    this.localMin.midDay();
                }
                else {
                    let dt = new DateObject();
                    dt.year -= this.calendarYearsRange || 3;
                    this.localMin = dt;
                }
            },

            setMax() {
                if (this.max) {
                    if (!(this.max instanceof DateObject)) {
                        this.localMax = new DateObject(this.max);
                    }
                    else {
                        this.localMax = this.max.copy();
                    }

                    this.localMax.midDay();
                }
                else {
                    let dt = new DateObject();
                    dt.year += this.calendarYearsRange || 3;
                    this.localMax = dt;
                }
            },

            bindToMinMax(date) {
                if ((this.computedMin) && (this.computedMin.time > date.time)) return this.computedMin.copy();
                if ((this.computedMax) && (this.computedMax.time < date.time)) return this.computedMax.copy();
                return date;
            },

            bindToDisplayMonths(date) {
                let startMonth = this.localDate.copy();
                
                let endMonth = startMonth.copy();
                if (this.numberOfMonths) {
                    endMonth.month += this.numberOfMonths;
                }
                endMonth.month += 1;

                // move back and forward one week for the previous and next month
                // to capture the out of month cells
                startMonth.date -= 7;
                endMonth.date += 7;

                return date;
            },

            monthProps(month, index) {
                let props = {
                    ...this.calendarBindProps
                };

                if (index == 0) {
                    props.showPreviousButton = true;
                }
                else {
                    props.showPreviousButton = false;
                }

                if (index == this.months.length - 1) {
                    props.showNextButton = true;
                }
                else {
                    props.showNextButton = false;
                }

                props.date = month;

                return props;
            },

            // when a month change, we need to advance the display
            // date by the number of the months that were changed
            // since this could happen from any month in the group
            // we'll use the index to update the first display date
            monthChange(newMonth, index) {
                newMonth.month = newMonth.month - index;
                this.localDate = this.bindToMinMax(newMonth);
                this.$emit('monthChange', newMonth.copy());
            },

            clear() {
                this.startDate = null
                this.endDate = null;
                this.hoverDate = null;
                this.localValue = null;
                this.invalidTypedDate = false;
                if (this.closeOnClear) {
                    this.showPopover = false;
                }
                this.$emit('clear');
                this.$emit('update:modelValue', null);
                this.$emit('input', null);
                this.$emit('change', null);
            },

            dateClick(calendarDay) {
                if (!calendarDay.selectable) return;

                if ((this.startDate) && (this.endDate)) {
                    this.startDate = calendarDay.date;
                    this.endDate = null;

                    this.$emit('selectStart', this.startDate.copy());
                }
                else if (this.startDate) {

                    this.pauseHover = true;
                    setTimeout(function() {
                        this.pauseHover = false;
                    }.bind(this), 1000);
                    this.hoverDate = null;
                    
                    this.endDate = calendarDay.date;

                    this.$emit('selectEnd', this.endDate.copy());
                    this.showPopover = false;
                }
                else {
                    this.startDate = calendarDay.date;
                    this.endDate = null;

                    this.$emit('selectStart', this.startDate.copy());
                }

                if (this.startDate) {
                    this.typedStartDate = this.startDate.format(this.format);
                }
                if (this.endDate) {
                    this.typedEndDate = this.endDate.format(this.format);
                }

                this.invalidTypedDate = false;
                this.emitEvent();
            },

            emitEvent() {
                
                if ((this.startDate) && (this.endDate)) {
                    // check if need to flip the dates
                    if (this.startDate.time > this.endDate.time) {
                        let hold = this.startDate;
                        this.startDate = this.endDate;
                        this.endDate = hold;
                    }
                }

                // set the typed values
                if (this.startDate) {
                    this.typedStartDate = this.startDate.format(this.format);
                }
                if (this.endDate) {
                    this.typedEndDate = this.endDate.format(this.format);
                }

                let localVal;
                if ((this.startDate) && (this.endDate)) {
                    localVal = new DateRange(this.startDate, this.endDate);
                    localVal.orient();
                    
                    this.setLocalValue(localVal);
                }
                
                this.$emit('update:modelValue', localVal);
                this.$emit('input', localVal);
                this.$emit('change', localVal);
            },
            
            dateEnter(calendarDay) {
                if (!calendarDay.selectable) return;
                if (this.pauseHover) return;
                this.hoverDate = calendarDay.date;
            },

            hidePopover() {
                this.showPopover = null;
                this.calendarLeave();
            },
            
            calendarLeave() {
                this.hoverDate = null;
            },

            isBlocked(date, useLocalMin, useLocalMax) {
                let min;
                let max;
                if (useLocalMin) {
                    min = this.localMin;
                }
                else {
                    min = this.computedMin;
                }
                if (useLocalMax) {
                    max = this.localMax;
                }
                else {
                    max = this.computedMax;
                }

                if ((min) && (date.time < min.time)) return true;
                if ((max) && (date.time > max.time)) return true;

                if (
                    (!this.calendarProps) ||
                    (!this.calendarProps.blockDates) ||
                    (!this.calendarProps.blockDates.length)
                 ) {
                     return false;
                }

                for (let i=0; i<this.calendarProps.blockDates.length; i++) {
                    let compare = this.calendarProps.blockDates[i];
                    if ((typeof compare == 'string') || (compare instanceof Date)) {
                        compare = new DateObject(compare);
                    }

                    if ((compare instanceof DateObject) && (compare.equalDate(date))) {
                        // if this is a date we should ignore during the min/max computed
                        // and we are calling from that function, ignore it
                        if (((useLocalMax) || (useLocalMin)) && (compare.ignoreComputedMinMax)) {
                            return false;
                        }
                        return true;
                    }
                    else if ((compare instanceof DateRange) && (compare.inRange(date)))  {
                        return true;
                    }
                }
                return false;
            },

            isDateVisible(dt) {
                for (let i=0; i<this.months; i++) {
                    if (dt.equalMonth(this.months[i])) {
                        return true;
                    }
                }

                return false;
            },

            focusOnInput(start) {
                let focusFunc = function() {
                    if ((this.$refs.inputGroupEl) && (this.$refs.inputGroupEl.$el)) {
                        let input;
                        if (start) {
                            input = this.$refs.inputGroupEl.$el.querySelector('input');
                        }
                        else {
                            input = this.$refs.inputGroupEl.$el.querySelector('input:last-of-type');
                        }
                        if (input) {
                            input.focus();
                        }
                    }
                }.bind(this);

                // we need to ensure the focus is not stolen
                // by the popover, but make it happens as soon
                // as possible
                focusFunc();
                requestAnimationFrame(focusFunc);
                setTimeout(focusFunc, 250);
            },

            focusStartTypeDate() {
                // the popover takes the focus, so we'll reset it
                if (!this.showPopover) {
                    this.showPopover = true;
                    this.focusOnInput(true);
                }
            },

            focusEndTypeDate() {
                let focusStart = false;
                if (!this.startDate) {
                    focusStart = true;
                }
                this.showPopover = true;
                this.focusOnInput(focusStart);
            },

            selectTypedStartDate() {
                let dt = null;
                // reset the start date to avoid running into
                // issues with the computed min if it was initially
                // selected
                this.startDate = null;

                if (this.typedStartDate) {
                    dt = DateObject.parse(this.typedStartDate);
                }
                
                if (dt) {
                    dt = this.bindToMinMax(dt);
                    if (this.isBlocked(dt)) {
                        
                        this.invalidTypedDate = true;

                        this.typedStartDate = null;
                        this.focusOnInput(true);
                        return;
                    }

                    this.startDate = dt;
                    this.invalidTypedDate = false;
                    
                    if (!this.isDateVisible(dt)) {
                        this.setLocalDate(dt.copy());
                    }
                }
                else {
                    this.startDate = null;
                    this.endDate = null;
                    this.invalidTypedDate = false;
                }

                if (this.startDate) {
                    this.$emit('selectStart', this.startDate.copy());
                }

                this.calendarLeave();
                this.emitEvent();
            },


            selectTypedEndDate() {
                let dt = null;
                // reset the end date to avoid running into
                // issues with the computed max if it was initially
                // selected
                this.endDate = null;
                
                if (this.typedEndDate) {
                    dt = DateObject.parse(this.typedEndDate);
                }
                
                if (dt) {
                    dt = this.bindToMinMax(dt);
                    if (this.isBlocked(dt)) {

                        this.invalidTypedDate = true;
                        this.typedEndDate = null;
                        this.focusOnInput(false);
                        return;
                    }

                    this.endDate = dt;
                    this.invalidTypedDate = false;
                    if (!this.isDateVisible(dt)) {
                        this.setLocalDate(dt.copy());
                    }
                }
                else {
                    this.invalidTypedDate = false;
                    this.endDate = null;
                }

                if (this.endDate) {
                    this.$emit('selectEnd', this.endDate.copy());
                }

                this.showPopover = false;

                this.calendarLeave();
                this.emitEvent();
            },
        }
    }
</script>