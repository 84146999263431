import { createRouter, createWebHistory } from 'vue-router';

import PublicRoutes from '@/router/public/index.js';
import ErrorRoutes from '@/router/errors.js';
//import DevGuideRoutes from '@/router/dev-guide.js';

import PartnersRoutes from '@/router/partners/index.js';
import OwnersRoutes from '@/router/owners/index.js';
import TravlersRoutes from '@/router/travelers/index.js';
import AccountRoutes from '@/router/account.js';

import AuthGuard from '@/classes/AuthGuard.js';
import Api from '@/classes/Api.js';
import UI from '@/classes/UI.js';
import MenuNotifications from '@/classes/MenuNotifications.js';
import Media from '@/classes/Media';
import ApiResponse from '@/classes/ApiResponse';
import MobileApp from '@/classes/MobileApp';


/**
 * Common meta data for routes
 * {
 *  group: String, // when the route group matches this, the active class will be added to the main menu
 *  collapseStartMenu: Boolean, // initially collapse the start menu
 *  enableStartMenuCollapse: Boolean, // enable the collapse action on the menu
 *  collapseEndMenu: Boolean, // initially collapse the end menu
 *  enableEndMenuCollapse: Boolean, // enable the collapse action on the menu
 *  authRequired: Boolean, // if the path requires authintication. Default to true for all routes
 *  permissions: String[], // an array of strings of allowed permission to access the route.
 *                  Only checked if authRequired is true (or not set = null)
 *  scrollBehavior: Function, // takes the "to" and "from" and must return a scroll position or false (to avoid scroll).
 *                              // If null, default scroll will take place
 *  resetBackButton: boolean, // when true, will reset the back button count to 0
 *  metaData: Object, // the page meta data @see AuthGuard.setPageMeta
 *  useLastMetaData: Boolean, // if we should use the last meta data
 *  floatingMenu: If the menu should float on top (allow content to show behind it)
 *                  e.g. full screen carsolu
 *  checkOutFlow: boolean If the page is part of the check-out flow, defaults to false
 *  multiRoute: If the view is for a multi route to avoid disposing it on route leave. String
 *  footerCtaType: String that defines the footer CTA type, defaults to "guests"
 *  footerCtaLabel: String that defines the footer CTA label, defaults to "We are here to answer your questions"
 *  isWidget: boolean that hide ui elements for widgets
 * }
 */

const routes = [
    ...PublicRoutes,
    ...OwnersRoutes,
    ...TravlersRoutes,
    ...PartnersRoutes,
    ...ErrorRoutes,
    ...AccountRoutes,
    //...DevGuideRoutes,
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    base: '/',
    routes,
    scrollBehavior (to, from, savedPosition) {

        // prevent map from auto scroll
        // in mobile firefox
        // if both routes are map routes,
        // use the default 
        if (
            (window.innerWidth <= 720) &&
            (navigator) &&
            (navigator.userAgent) &&
            (navigator.userAgent.toLowerCase().indexOf('firefox') == -1) &&
            (from.fullPath.indexOf('/book-a-property/map') != -1) &&
            (to.fullPath.indexOf('/book-a-property/map') != -1)
        ) {
            return {};
        }

        let results;
        let noScroll = false;

        if (to.meta.scrollBehavior) {
            let routeScroll = to.meta.scrollBehavior(to, from);
            if (routeScroll != null) {
                if (routeScroll == false) {
                    noScroll = true;
                }
                results = routeScroll;
            }
        }
        
        if (results == null) {
            if (savedPosition) {
                results = savedPosition;
            }
            else if (to.hash) {
                results = {
                    el: to.hash,
                    behavior: 'smooth',
                };
            }
            else {
                results = {top: 0};
            }
        }

        // firefix might miss the scroll
        // and keep the page on the bottom
        // if the content rendered is delayed
        if (
            (navigator) &&
            (navigator.userAgent) &&
            (navigator.userAgent.toLowerCase().indexOf('firefox') == -1)
        ) {
            return results;
        }

        if (!noScroll) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(results);
                }, 500);
            });
        }
    }
});

/**
 * BACK BUTTON COUNT MAINTAINCE
 * To keep track of the route changes
 * we'll override the vue router
 * standard methods and create 
 * a custom methods that call the original
 * functions
 * 
 * Vue router doesn't have (as far as I can tell)
 * events that we can hook into for 
 * push, replace, or back
 */
router._originalSmPush = router.push;

/**
 * iOS webview back button doesn't work
 * we'll resolve it by creating our own history
 * and moving forward to the last route
 */
let _smIOSHistory = [];

router.push = function(to) {
    // check if the route matches the current location

    let pathName = location.pathname;

    // if the path ends with '/', trim it
    if (pathName.charAt(pathName.length - 1) == '/') {
        pathName = pathName.substring(0, pathName.length - 1);
    }
    
    if (to == pathName) {
        return router._originalSmPush(to);
    }
    
    UI.adjustBackButtonCount(1);
    if (MobileApp.inApp()) {
        _smIOSHistory.push(to);
        while (_smIOSHistory.length > 500) {
            _smIOSHistory.unshift();
        }
    }
    return router._originalSmPush(to);
}

if (MobileApp.inApp()) {
    router._originalSmBack = router.back;
    router.back = function() {
        if (_smIOSHistory.length) {
            // pop the current route
            _smIOSHistory.pop();
            UI.adjustBackButtonCount(-1);
            if (_smIOSHistory.length) {
                let lastRoute = _smIOSHistory.pop();
                this.push(lastRoute);
                return;
            }
        }
        
        router._originalSmBack();
    }
}

var _currentHistoryPosition = 0;
function _setCurrentHistoryPosition() {
    // to make sure the back and forward buttons
    // are triggered after the history, we'll use
    // a small timeout
    setTimeout(function() {
        if ((history) && (history.state) && (history.state.position)) {
            _currentHistoryPosition  = history.state.position;
        }
    }, 250);
}

window.addEventListener('popstate', function(evt) {
    if ((evt) && (evt.state) && (evt.state.position)) {
        UI.adjustBackButtonCount(evt.state.position - _currentHistoryPosition);
    }
});

router.beforeEach((to) => {
    to.params = Api.normalizeRouteParam(to);
    AuthGuard.setCurrentRoute(to);

    // rest the back count on dashboards
    if ((to.meta) && (to.meta.resetBackButton)) {
        UI.resetBackButtonCount();
    }

    // clearing cached object urls
    Media.revokeObjectUrls();
    
    // clear the active response
    ApiResponse.resetActive();

    // check for notifications update from to
    MenuNotifications.checkRoute(to.fullPath);
});

router.afterEach(_setCurrentHistoryPosition);

MenuNotifications.setRouter(router);

export default router
