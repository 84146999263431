export default [
    
    {
        path: '/aesthetic-profile',
        components: {
            default: () => import(/* webpackChunkName: "public.external.properties" */ '@/views/Public/AI/Profile.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/ai',
            multiRoute: 'public.ai.profile',
            label: 'Aesthetic Profile',
        }
    },

    {
        path: '/aesthetic-profile/:id(\\d+)/:hash?',
        components: {
            default: () => import(/* webpackChunkName: "public.external.properties" */ '@/views/Public/AI/ViewProfile.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/ai',
            multiRoute: 'public.ai.profile.view',
            label: 'Aesthetic Profile',
        }
    },

    {
        path: '/aesthetic-profile/:id(\\d+)/share/:hash',
        components: {
            default: () => import(/* webpackChunkName: "public.external.properties" */ '@/views/Public/AI/Share.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/ai',
            multiRoute: 'public.ai.profile.share',
            label: 'Aesthetic Profile',
        }
    },
  ];