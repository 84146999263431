<template>
    <textarea v-if="(lines || 1) > 1" v-bind="$attrs" class="form-control" :class="cssClass" v-model="localValue" :disabled="disabled" :readonly="readonly" :list="optionsId" @input="emit('update:modelValue'); emit('input');" @change="emit('change')" :rows="lines" :required="required" ref="inputEl" :autocomplete="computeAutoComplete"></textarea>

    <input v-else v-bind="$attrs" :type="type || 'text'" class="form-control" :class="cssClass" v-model="localValue" :disabled="disabled" :readonly="readonly" :list="optionsId" @input="emit('update:modelValue'); emit('input');" @change="emit('change')" @click="updateAppState" :required="required" ref="inputEl" :autocomplete="computeAutoComplete" />
    
    <datalist :id="optionsId" v-if="hasOptions">
        <option v-for="(option, i) in options" :key="`${optionsId}-${i}`" :value="option.value" />
    </datalist>

    <alert v-if="!disabled && !readonly && missingPermissions && missingPermissions.length" class="mb-0 small">
        The following permissions are missing which could break the app or limit it's functionality: {{ missingPermissions.join(', ') }}
        <br />
        <a href="#" @click.prevent="openAppSettings">Click here</a> to open app settings to update your permissions.
    </alert>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';
    import Alert from '@/components/UI/Alert.vue';
    import Utilities from '@/classes/Utilities.js';
    import Permissions from '@/classes/Permissions.js';
    import MobileApp from '@/classes/MobileApp.js';

    import { UseUIStore } from '@/store/UI.js';

    export default {
        inheritAttrs: false,

        components: {
            Alert,
        },

        setup() {
            return {
                uiStore: UseUIStore(),
            };
        },

        data() {
            return {
                localValue: null,
                localOptionsId: null,

                permissionUpdates: Permissions.permissionsUpdated,
                missingPermissions: null,
            };
        },

        // the events prefixed with vue: are used for nested inputs compents
        // to avoid double trigger the events
        // eg. Forms/Number.vue
        emits: ['update:modelValue', 'input', 'change', 'v:input', 'v:change'],

        props: {
            type: {
                type: String,
                default: 'text',
            },

            lines: {
                type: Number,
                default: 1,
            },

            value: {
                default: null,
            },
            modelValue: {
                default: null,
            },

            size: {
                type: String,
            },

            valid: {
                type: Boolean,
                default: null,
            },

            required: {
                type: Boolean,
                default: false,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            readonly: {
                type: Boolean,
                default: false,
            },

            options: {
                type: Array,
                required: false,
                validator: function(options) {
                    if ((options == null) || (options.length == 0)) return false;
                    return UI.validateFormOptions(options);
                },
            },

            inputGroupText: {
                type: Boolean,
                default: false,
            },

            prefixedEvents: {
                type: Boolean,
                default: false,
            },

            autocomplete: {
                type: String,
            },
        },

        watch: {
            modelValue(newVal) {
                if (this.type != 'file') {
                    this.localValue = newVal;
                }
            },

            value(newVal) {
                if (this.type != 'file') {
                    this.localValue = newVal;
                }
            },

            permissionUpdates() {
                this.setMissingPermission();
            },
        },

        created() {
            if (this.type != 'file') {
                if (this.modelValue != null) {
                    this.localValue = this.modelValue;
                }
                else {
                    this.localValue = this.value;
                }
            }
            
            this.setMissingPermission();
        },
        
        computed: {
            cssClass() {
                let cls = [];

                if (this.size) cls.push('form-control-'+this.size);
                if (this.valid != null) {
                    if (this.valid) cls.push('is-valid');
                    else cls.push('is-invalid');
                }
                if (this.disabled) cls.push('disabled');
                if (this.inputGroupText) cls.push('input-group-text');
                if (this.type == 'color') cls.push('form-control-color');

                return cls;
            },

            hasOptions() {
                return ((this.options) && (this.options.length));
            },

            optionsId() {
                if (this.hasOptions) {
                    this.setOptionId();
                    return this.localOptionsId;
                }
                return null;
            },

            computeAutoComplete() {
                if (this.autocomplete) {
                    return this.autocomplete;
                }
                else if (this.type == 'email') {
                    return 'email';
                }
                else if (this.type == 'tel') {
                    return 'tel';
                }
                else if (
                    (this.$attrs) &&
                    (this.$attrs.name) && 
                    (
                        (this.$attrs.name == 'first-name') ||
                        (this.$attrs.name == 'firstName')
                    )
                ) {
                    return 'given-name';
                }
                else if (
                    (this.$attrs) &&
                    (this.$attrs.name) && 
                    (
                        (this.$attrs.name == 'last-name') ||
                        (this.$attrs.name == 'lastName')
                    )
                ) {
                    return 'family-name';
                }
                else if (
                    (this.$attrs) &&
                    (this.$attrs.name) && 
                    (this.$attrs.name == 'name')
                ) {
                    return 'name';
                }

                else if (this.type == 'password') {
                    return 'current-password';
                }
                return 'on';
            },
        },

        methods: {
            async setMissingPermission() {
                if (this.type == 'file') {
                    this.missingPermissions = await this.uiStore.fileInputDenidPermissions();
                }
            },

            setOptionId() {
                if (!this.localOptionsId) {
                    this.localOptionsId = Utilities.uniqueId('input-options');
                }
            },

            emit(evt) {
                if ((this.prefixedEvents) && (evt != 'update:modelValue')) {
                    evt = 'v:'+evt;
                }
                let val = this.localValue;
                if (
                    (this.type == 'file') &&
                    (this.$refs.inputEl) &&
                    (this.$refs.inputEl.files) &&
                    (this.$refs.inputEl.files.length)
                ) {
                    val = this.$refs.inputEl.files;
                }
                this.$emit(evt, val);
            },

            updateAppState() {
                if (this.type == 'file') {
                    MobileApp.appTakingPhoto();
                }
            },

            focus() {
                if (this.$refs.inputEl) {
                    this.$refs.inputEl.focus();
                }
            },
        }
    }
</script>