import DashboardLinks from '@/router/travelers/dashboard.js';
import ProfileRoutes from '@/router/travelers/profile.js';
import AccountingRoutes from '@/router/travelers/accounting.js';
import BookingsRoutes from '@/router/travelers/bookings.js';
import ConciergeRoutes from '@/router/travelers/concierge.js';
import FeedbackRoutes from '@/router/travelers/feedback.js';
import ResolutionCenterRoutes from '@/router/travelers/resolution-center.js';
import AiRoutes from '@/router/travelers/ai.js';

export default  [
    ...DashboardLinks,
    ...ProfileRoutes,
    ...AccountingRoutes,
    ...BookingsRoutes,
    ...ConciergeRoutes,
    ...FeedbackRoutes,
    ...ResolutionCenterRoutes,
    ...AiRoutes,
]

