import LocaleConfig from '@/config/locale.json';
import Utilities from '@/classes/Utilities.js';
import DateObject from '@/classes/DateObject.js';


/**
 * A class to format numbers, dates and other formatting utilities
 */
export default class Formatter {
    // store different formatters by decimal places
    static #numberRegExp;
    static #localeNumberParts;
    static #numberFormatters = {};

    /**
     * Round a number to specific percison
     * @param {number} val the number we want to format
     * @param {number} decimalPlaces the number of decimals we want
     * @return {number} the number rounded
     */
    static round(val, decimalPlaces) {
        if ((!decimalPlaces) || (decimalPlaces < 0)) decimalPlaces = 0;

        if (decimalPlaces) {
            let pow = Math.pow(10, decimalPlaces);
            return Math.round((val + (Number.EPSILON || 0)) * pow) / pow;
        }
        else {
            return Math.round(val);
        }
    }

    /**
     * Get the numbers different parts
     * based on the local
     * @return {Object} object with the following keys
     *  thousandsSeparator
     *  decimalSeparator
     *  negativeSign
     *  accountingNegativePrefix
     *  accountingNegativePostfix
     */
    static numberParts() {
        if (!Formatter.#localeNumberParts) {
            // when we support multiple locals, we'll use the Int library
            // and reverse the numbers, but for now we'll just use the config
            // settings
            Formatter.#localeNumberParts = {
                thousandsSeparator: LocaleConfig.thousandsSeparator,
                decimalSeparator: LocaleConfig.thousandsSeparator,
                negativeSign: LocaleConfig.negativeSign,
                accountingNegativePrefix: LocaleConfig.accountingNegativePrefix,
                accountingNegativePostfix: LocaleConfig.accountingNegativePostfix,
            };

            Object.seal(Formatter.#localeNumberParts);
        }

        return Formatter.#localeNumberParts;
    }

    /**
     * Checks if a specific value is numeric
     * @return boolean
     */
    static isNumeric(val) {
        if (typeof val == 'number') return true;
        if ((typeof val == 'string') && (val.indexOf('e') == -1)) {
            let num = parseFloat(val);
            return (!isNaN(num) && (num == val));
        }
        return false;
    }

    /**
     * Convert a string to a number
     * This will remove any characters
     * that do not match the number locale
     * and parse the output into float
     * @param {string|number} val the value we want to convert to number
     * @param {boolean} accounting if the number is an accounting negative
     * @return {number}
     */
    static parseNumber(val) {
        if (val == null) return null;
        if (typeof val == 'number') return val;

        // we'll create a reg exp using the config settings
        // first escape the decimal and negative mark

        // first reverse the accounting negative value
        let numberParts = Formatter.numberParts();

        if (!Formatter.#numberRegExp) {

            Formatter.#numberRegExp = {
                negativeSign: new RegExp('/'+Utilities.escapeRegExp(numberParts.negativeSign)+'/', 'g'),
                multiNegative: /-+/g,
                decimalSeparator: new RegExp('/'+Utilities.escapeRegExp(numberParts.decimalSeparator)+'/', 'g'),
                removeNoneNumber: /[^\d.-]/g
            };
        }

        val = val.toString();

        if (
            ((numberParts.accountingNegativePrefix != '') && (val.substring(0, 1) == numberParts.accountingNegativePrefix)) &&
            ((numberParts.accountingNegativePostfix != '') && (val.substring(val.length - 1) == numberParts.accountingNegativePostfix))
        ) {
            val = ('-'+val).replace(Formatter.#numberRegExp.multiNegative, '-');
        }
        
        if (numberParts.negativeSign != '-') {
            val = val.replace(Formatter.#numberRegExp.negativeSign, '-').replace(Formatter.#numberRegExp.multiNegative, '-');
        }
        if (numberParts.decimalSeparator != '.') {
            val = val.replace(Formatter.#numberRegExp.decimalSeparator, '.');
        }
        
        let num = val.replace(Formatter.#numberRegExp.removeNoneNumber, '');
        num = parseFloat(num);
        if (isNaN(num)) return null;
        return num;
    }

    /**
     * Format a number
     * @param {number} val the number we want to format
     * @param {number} decimalPlaces the number of decimals
     * @param {boolean} compact if we want to make large numbers short e.g. 1000 as 1k
     * @return {string} the number formatted
     */
    static number(val, decimalPlaces, compact) {
        if (decimalPlaces == null) decimalPlaces = 0;

        let key = 'number-'+decimalPlaces.toString();
        if (compact) key += '-compact';

        if (!Formatter.#numberFormatters[key]) {
            let options = {
                minimumFractionDigits: decimalPlaces,
                maximumFractionDigits: decimalPlaces,
            }
            if (compact) {
                options.notation = 'compact';
            }
            Formatter.#numberFormatters[key] = new Intl.NumberFormat(LocaleConfig.locale, options);
        }

        return Formatter.#numberFormatters[key].format(val);
    }

    /**
     * Add ordinal to a number
     * This works on ints only
     * @param {Number} val the number we are formatting
     * @return {String} the number formatted with ordinal attached
     */
    static numberOrdinal(val) {
        let formatted = Formatter.number(val);

        let ordinal = LocaleConfig.numberOrdinal;
        if (ordinal) {
                
            let val = 'th';

            let lastDigit = formatted.substring(formatted.length - 1);
            let lastTwoDigits;
            if (formatted.length >= 2) {
                lastTwoDigits = formatted.substring(formatted.length - 2);
            }
            if (lastDigit == '1') {
                if (lastTwoDigits != '11') {
                    val = 'st';
                }
            }
            if (lastDigit == '2') {
                if (lastTwoDigits != '12') {
                    val = 'nd';
                }
            }
            if (lastDigit == '3') {
                if (lastTwoDigits != '13') {
                    val = 'rd';
                }
            }

            if (ordinal[val]) {
                formatted += val;
            }
        }

        return formatted;
    }

    /**
     * Format a number into money format
     * @param {number} val the number we want to format
     * @param {number} decimalPlaces the number of decimals
     * @param {boolean} compact if we want to make large numbers short e.g. 1000 as 1k
     * @param {boolean} accounting if we want to display accounting negative values e.g. -100 as (100)
     * @return {string} the number formatted as money
     */
    static money(val, decimalPlaces, compact, accounting) {

        if (decimalPlaces == null) decimalPlaces = 2;
        let key = 'money-'+decimalPlaces.toString();
        if (compact) key += '-compact';
        if (accounting) key += '-accounting';

        if (!Formatter.#numberFormatters[key]) {
            let options = {
                style: 'currency',
                currency: LocaleConfig.currency,
                minimumFractionDigits: decimalPlaces,
                maximumFractionDigits: decimalPlaces,
                currencySign: 'standard',
            }
            if (accounting) {
                options.currencySign = 'accounting'; 
            }
            if (compact) {
                options.notation = 'compact';
            }
            Formatter.#numberFormatters[key] = new Intl.NumberFormat(LocaleConfig.locale, options);
        }

        return Formatter.#numberFormatters[key].format(val);
    }

    /**
     * Format a percentage value
     * Since the Int library uses fractions to format a percentage
     * we'll divide the value by 100
     * @param {number} val the full percentage value we want (e.g. 50 for 50%)
     * @param {number} decimalPlaces the number of decimals
     * @param {boolean} signed add the + sign to the percentage (except when 0) (e.g. +50%)
     * @return {string} the number formatted as percent
     */
    static percent(val, decimalPlaces, signed) {
        if (decimalPlaces == null) decimalPlaces = 0;
        let key = 'percent-'+decimalPlaces.toString();
        if (signed) key += '-signed';

        if (!Formatter.#numberFormatters[key]) {
            let options = {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: decimalPlaces,
                signDisplay: 'auto',
            }
            if (signed) {
                options.signDisplay = 'exceptZero'; 
            }
            Formatter.#numberFormatters[key] = new Intl.NumberFormat(LocaleConfig.locale, options);
        }

        return Formatter.#numberFormatters[key].format(val / 100);
    }

    /**
     * Parse a date from a string
     * String must be in the format Y-m-d H:i:s (seconds optional)
     * Custom values are "now" "today" (same as now, but at midnight), "tomorrow" (now + 1 day), "yesterday" (now - 1 day)
     * @param {string|number|Date|DateObject} date the date we want to parse
     * @param {boolean} utc if the date string in UTC and needs to converted to localtime zone (only effect strings)
     * @return {Date}
     */
    static parseDate(date, utc) {
        if (date == null) return null;
        if (date instanceof Date) {
            if (utc) {
                return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
            }
            return date;
        }
        else if (typeof date == 'number') {
            date = new Date(date);
            if (utc) {
                return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
            }
            return date;
        }
        else if (date instanceof DateObject) {
            date = date.dateObject;
            if (utc) {
                return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
            }
            return date;
        }

        if (date.toLowerCase() == 'now') {
            return new Date();
        }
        else if (date.toLowerCase() == 'today') {
            let date = new Date();
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);

            return date;
        }
        else if (date.toLowerCase() == 'tomorrow') {
            let date = new Date();
            date.setDate(date.getDate() + 1);

            return date;
        }
        else if (date.toLowerCase() == 'yesterday') {
            let date = new Date();
            date.setDate(date.getDate() - 1);

            return date;
        }

        // we might have a Ymd formatted date (from old DB setup)
        // e.g. 20210123 for 2021-01-23
        // so we'll add the dashes to ensure the date is formatted
        // correctly
        date = date.replace(/(\d{8})/, function(val) {
            let year = val.substring(0, 4);
            let month = val.substring(4, 6);
            let date = val.substring(6);
            return year+'-'+month+'-'+date;
        });

        // split the date and time parts
        let arr;
        if (date.indexOf('T') != -1) {
            arr = date.split('T');
        }
        else {
            arr = date.split(' ');
        }
        let dateParts = arr[0].split('-');

        let  year = dateParts[0];
        let month = Formatter.parseNumber(dateParts[1]);
        if (!month) month = 0;
        else month = month - 1;
        let day = dateParts[2];

        let hours = 0;
        let minutes = 0;
        let seconds = 0;
        if (arr.length > 1) {
            let timeParts = arr[1].split(':');
            hours = timeParts[0];
            minutes = timeParts[1];
            if (timeParts.length > 2) {
                seconds  = timeParts[2]
            }
        }

        if (utc) {
            return new Date(Date.UTC(year, month, day, hours, minutes, seconds));
        }

        return new Date(year, month, day, hours, minutes, seconds);
    }

    /**
     * Format a date time object
     * to a string, this function uses
     * the similar formatting styles as PHP
     * 
     * Supported format values are
     * 
     * d, D, j, l (lowercase 'L'), N, S, w, F, m, M, n, t, Y, a, A, g, G, h, H, i, s, v, 
     * \ to escape characters
     * 
     * @see https://www.php.net/manual/en/datetime.format.php
     * @param {date|DateObject} date the date we want to format
     * @param {string} format the date format, defaults to 'Y-m-d'
     * @return {string} the  date formatted
     */
    static date(date, format) {
        if (!format) format = 'Y-m-d';

        if (date instanceof DateObject) date = date.dateObject;

        let re = [];

        for (let i=0; i<format.length; i++) {
            let char = format.charAt(i);
            // escape charaters
            if (char == '\\') {
                i++;
                continue;
            }

            if ((char == 'd') || (char == 'j')) {
                let val = date.getDate();
                if (char == 'd') {
                    val = val.toString().padStart(2, '0');
                }
                re.push(val);
            }
            else if ((char == 'D') || (char == 'l')) {
                let arr = (char == 'D') ? LocaleConfig.days.short : LocaleConfig.days.long;
                re.push(arr[date.getDay()]);
            }
            else if ((char == 'N') || (char == 'w')) {
                let day = date.getDay();
                if (char == 'N') {
                    if  (day == 0) day = 7;
                    else day++;
                }
                re.push(day);
            }
            else if (char == 'S') {
                let ordinal = LocaleConfig.numberOrdinal;
                if (!ordinal) continue;
                let dt = date.getDate();
                let val = 'th';

                // if we use other locals beside english, we'll probably need
                // to use a locale method to replace this
                if ((dt == 1) || (dt == 21) || (dt == 31)) val = 'st';
                else if ((dt == 2) || (dt == 22)) val = 'nd';
                else if ((dt == 3) || (dt == 23)) val = 'rd';

                if (ordinal[val]) {
                    re.push(ordinal[val]);
                }
            }
            else if ((char == 'F') || (char == 'M')) {
                let arr = (char == 'M') ? LocaleConfig.months.short : LocaleConfig.months.long;
                re.push(arr[date.getMonth()]);
            }
            else if ((char == 'm') || (char == 'n')) {
                let val = date.getMonth() + 1;
                if (char == 'm') {
                    val = val.toString().padStart(2, '0');
                }
                re.push(val);
            }
            else if (char == 't') {
                let newDate = new Date(date.getTime());
                newDate.setMonth(newDate.getMonth() + 1);
                newDate.setDate(0);

                re.push(newDate.getDate());
            }
            else if (char == 'Y') {
                re.push(date.getFullYear().toString().padStart(4, 0));
            }
            else if ((char == 'a') || (char == 'A')) {
                let val = 'am';
                let hour = date.getHours();
                if (hour >= 12) val = 'pm';
                if (char == 'A') val = val.toUpperCase();
                re.push(val);
            }
            else if ((char == 'g') || (char == 'G') || (char == 'h') || (char == 'H')) {
                let hour = date.getHours();
                if ((char == 'g') || (char == 'h')) {
                    if (hour == 0) hour = 12;
                    else if (hour > 12) hour -= 12;
                }
                if ((char == 'h') || (char == 'H')) {
                    hour = hour.toString().padStart(2, '0');
                }

                re.push(hour);
            }
            else if (char == 'i') {
                re.push(date.getMinutes().toString().padStart(2, '0'));
            }
            else if (char == 's') {
                re.push(date.getSeconds().toString().padStart(2, '0'));
            }
            else if (char == 'v') {
                re.push(date.getMilliseconds());
            }
            else {
                re.push(char);
            }
        }

        return re.join('');
    }

    /**
     * Basic pluralize method
     * takes the count, the single word and the plural count
     * 0 or null will use plural
     * @param {Number} count the items count
     * @param {String} plural the plural term
     * @param {String} single the single term
     * @return {String}
     */
    static pluralize(count, plural, single) {
        if (!single) {
            if (plural.substring(plural.length - 2) == 'es') single = plural.substring(0, plural.length - 2);
            else single = plural.substring(0, plural.length - 1);
        }
        if (!count) return plural;
        if (count == 1) return single;
        return plural;
    }

    /**
     * Create a readable time string from
     * the number of seconds
     * e.g. 120 will return 2 minutes
     * @param {Number} seconds the number of seconds
     * @param {Boolean} short if we need a short format
     * @param {Boolean} includeSeconds if we need to include the seconds, ignored if seconds is less than 60
     * @return {String}
     */
    static secondsToTimeString(seconds, short, includeSeconds) {
        seconds = Formatter.round(seconds);

        if (seconds < 60) {
            if (short) return seconds+'S';
            return seconds+' '+Formatter.pluralize(seconds, 'seconds', 'second');
        }

        let minutes = Math.floor(seconds / 60);
        seconds = seconds - (minutes * 60);

        let hours = Math.floor(minutes / 60);
        minutes = minutes - (hours * 60);

        let days = Math.floor(hours / 24);
        hours = hours - (days * 24);

        let weeks = Math.floor(days / 7);
        days = days - (weeks * 7);

        let months = Math.floor(weeks / 4.34524);
        weeks = weeks - (months * 4.34524);

        let years = Math.floor(months / 12);
        months = months - (years * 12);

        let re = [];
        if (short) {
            if (years > 0) {
                re.push(years+'Y');
            }
            if (months > 0) {
                re.push(months+'M');
            }
            if (weeks > 0) {
                re.push(weeks+'W');
            }
            if (days > 0) {
                re.push(days+'D');
            }
            if (hours > 0) {
                re.push(hours+'H');
            }
            if (minutes > 0) {
                re.push(minutes+'M');
            }
            if ((seconds > 0) && (includeSeconds)) {
                re.push(seconds+'S');
            }

            return re.join(' ');
        }
        else {
            if (years > 0) {
                re.push(years+' '+Formatter.pluralize(years, 'years', 'year'));
            }
            if (months > 0) {
                re.push(months+' '+Formatter.pluralize(months, 'months', 'month'));
            }
            if (weeks > 0) {
                re.push(weeks+' '+Formatter.pluralize(weeks, 'weeks', 'week'));
            }
            if (days > 0) {
                re.push(days+' '+Formatter.pluralize(days, 'days', 'day'));
            }
            if (hours > 0) {
                re.push(hours+' '+Formatter.pluralize(hours, 'hours', 'hour'));
            }
            if (minutes > 0) {
                re.push(minutes+' '+Formatter.pluralize(minutes, 'minutes', 'minute'));
            }
            if ((seconds > 0) && (includeSeconds)) {
                re.push(seconds+' '+Formatter.pluralize(seconds, 'seconds', 'second'));
            }

            if (re.length == 2) {
                return re.join(' and ');
            }
            else if (re.length > 2) {
                re[re.length - 1] = 'and '+re[re.length - 1];
            }
        
            return re.join(', ');
        }
    }
}